import React from 'react'

function Thankyou() {
  return (
    <div>
      <div className='Carouseldiv'>

        <div align="center">
          <h1>Thank you for registering your kit and completing the health questionnaire.</h1>
          <p>You can now send your sample back to the laboratory using the pre-paid envelope within your test kit. Thank you. </p>
        </div>




      </div>
    </div>
  )
}

export default Thankyou
